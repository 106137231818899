/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import ContentHero from '../Content/Hero'
import { Fragment } from 'react'
import { Box } from 'theme-ui'
import { Grid } from 'theme-ui'
import { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button } from 'theme-ui'
import RichTextRenderer from '../Content/RichText'

const AboutPage = ({ page }) => {
  const [activeTab, setActiveTab] = useState(
    page.sections?.length ? page.sections[0] : null
  )

  return (
    <Fragment>
      {page.hero ? <ContentHero hero={page.hero} /> : null}

      {activeTab ? (
        <Grid columns={[1, null, '1fr 1fr']} sx={{ backgroundColor: 'white' }}>
          <Box sx={{ position: 'relative' }}>
            {page.sections?.map(section => (
              <GatsbyImage
                image={section.images[0]?.gatsbyImageData}
                title={section.images[0]?.title}
                alt={section.images[0]?.description ?? section.images[0]?.title}
                sx={{
                  display: activeTab.id === section.id ? 'block' : 'none',
                  height: '100%'
                }}
                key={`${section.id}-${section.images[0]?.id}`}
              />
            ))}
          </Box>

          <Box sx={{ pt: [null, null, 'var(--nav-bar-height)'] }}>
            <Grid
              as="nav"
              sx={{
                gridTemplateColumns: `repeat(${page.sections?.length}, 1fr)`,
                alignItems: 'center',
                justifyContent: 'space-evenly',
                px: ['1.6rem', '2.4rem', null, 5],
                pt: 5,
                pb: [3, null, 5],
                gridGap: [1, 2]
              }}
            >
              {page.sections?.map(section => (
                <Button
                  onClick={() => setActiveTab(section)}
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: ['1.3rem', '1.4rem', '1.4rem'],
                    border: 0,
                    borderBottom:
                      activeTab.id === section.id ? '2px solid black' : 0,
                    borderRadius: 0,
                    px: 0,
                    ':hover': {
                      '--color': 'black',
                      '--background-color': 'transparent'
                    }
                  }}
                  key={section.id}
                >
                  {section.title}
                </Button>
              ))}
            </Grid>

            <Box
              sx={{
                px: [4, 5, 4, 5],
                pb: 5,
                p: { fontSize: ['1.4rem', '1.4rem', '1.4rem'] }
              }}
            >
              <RichTextRenderer richText={activeTab.heading} />

              <div
                sx={{ mt: ['4rem', null, '8rem'] }}
                dangerouslySetInnerHTML={{
                  __html: activeTab.description?.childMarkdownRemark?.html
                }}
              />
            </Box>
          </Box>
        </Grid>
      ) : null}
    </Fragment>
  )
}

AboutPage.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    hero: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        fluid: PropTypes.object.isRequired
      })
    }),
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        heading: PropTypes.shape({
          raw: PropTypes.string.isRequired
        }),
        description: PropTypes.shape({
          childMarkdownRemark: PropTypes.shape({
            html: PropTypes.string.isRequired
          }).isRequired
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
            fluid: PropTypes.object.isRequired
          })
        )
      })
    )
  }).isRequired
}

export default AboutPage
