/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { graphql, navigate } from 'gatsby'
import AboutPage from '~/components/About'

const About = ({ data: { contentfulPage: page } }) => {
  useEffect(() => {
    if (!page) {
      navigate('/')
    }
  }, [])

  return (
    <Layout showBreadcrumbs={false}>
      {page && (
        <>
          <Metadata
            title={page.metaTitle || page.title}
            description={
              page.metaDescription?.metaDescription ||
              `Super73's ${page.title} page default description`
            }
          />

          <AboutPage page={page} />
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    contentfulPage(slug: { eq: "about-super73" }) {
      id
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
      sections {
        id
        slug
        title
        heading {
          raw
        }
        images {
          id
          title
          gatsbyImageData(width: 1600, layout: CONSTRAINED)
          description
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default About
